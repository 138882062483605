import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { IcArrowDown, IcCancel } from 'components/icons';
import { get, isEqual, debounce, pickBy } from 'lodash';
import { Dropdown, Spinner, Stack } from '@koob/margaret';
import {
  PopoverContainer,
  PopoverMenu as RawPopoverMenu,
  PopoverItem,
  PopoverItemButton,
} from '@koob/margaret';
import { useQuery } from '@apollo/client';
import Scrollbars from 'react-custom-scrollbars';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import { useBooking } from 'hooks';

const PopoverMenuInner = styled(Scrollbars)``;

const PopoverMenu = styled(RawPopoverMenu)`
  max-height: none;
  overflow-y: initial;
`;

const PopoverFooter = styled(Stack)``;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--location-search-trigger-height, auto);
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 4px;
  line-height: 15px;
  min-height: 36px;
  position: relative;
  transition: border-color 100ms ease;
  width: 232px;

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      background-color: ${({ theme }) => theme.backgroundDisabled};
      color: ${({ theme }) => theme.disabled};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

const PlaceHolderLabel = styled.div`
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;
  white-space: nowrap;

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.disabled};
    `}

  ${({ variant }) =>
    variant === 'transparent' &&
    `
      color: #ffffff; 
      font-style: none;
    `}
`;

const SearchInput = styled.input`
  outline: none;
  border: 0;
  width: 262px;
  padding: 0;
`;

const LabelOption = styled.p`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-bottom: 0;
  color: ${({ theme }) => theme.darkSeparator};
  margin: 0;
`;

const PopoverSearchItem = ({ title, kind, data, value, onChange }) => {
  const nodes = data?.nodes ?? data;
  return (
    <PopoverItem>
      <LabelOption>
        {title} ({nodes?.length})
      </LabelOption>
      {nodes?.map(({ id, title, displayName, name }) => (
        <PopoverItemButton
          style={{ fontSize: 14 }}
          key={id}
          type="button"
          onClick={() =>
            onChange({ title: title ?? displayName ?? name, id, kind })
          }
          isActive={isEqual(value, title ?? displayName ?? name)}
        >
          {title ?? displayName ?? name}
        </PopoverItemButton>
      ))}
    </PopoverItem>
  );
};

const LocationSearchableSelect = ({
  placeholder,
  onChange,
  onAfterChange,
  disabled,
  wrapperStyle,
  inputStyle,
  query,
  pathToEdges,
  popoverFooter,
  variables,
  renderSelectedOption,
  emptyState,
  hasError,
  deletable,
  kind,
  initialValue,
}) => {
  const dropdownRef = useRef();
  const popoverRef = useRef();
  const inputRef = useRef();

  const { filters } = useBooking();
  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [value, setValue] = useState(initialValue ?? filters?.location);

  const pathToPageInfo = pathToEdges.concat('pageInfo');

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const { loading, data, fetchMore } = useQuery(query, {
    variables: pickBy({ search: debouncedSearch, ...variables }),
    skip: !isOpen,
    fetchPolicy: 'network-only',
  });

  const options = get(data, pathToEdges, []);

  const countriesOptions = options?.countries;
  const regionsOptions = options?.regions;
  const citiesOptions = options?.cities;
  const hotelsOptions = options?.hotels;
  const experiencesOptions = options?.experiences?.nodes?.filter(
    exp => exp?.experiencableType === 'Program',
  );

  const handleScroll = debounce(({ top, scrollHeight }) => {
    const pageInfo = get(data, pathToPageInfo, {});

    if (
      (top > 0.85 || scrollHeight < 250) &&
      !loading &&
      pageInfo?.hasNextPage
    ) {
      fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      });
    }
  }, 500);

  const removeFilter = e => {
    e.preventDefault();
    onChange('');
    setSearch('');
    setValue(null);
  };

  const handleChange = value => {
    onChange(value);
    setValue(value);

    if (Boolean(onAfterChange)) {
      onAfterChange(value);
    }
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  useDeepCompareEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ value }]);

  useEffect(() => {
    setSearch('');

    if (!isOpen) {
      return;
    }

    inputRef.current.focus();
  }, [isOpen]);

  useEffect(() => {
    setValue(filters?.location);
  }, [filters]);

  return (
    <Dropdown
      disabled={disabled}
      ref={dropdownRef}
      wrapperStyle={wrapperStyle}
      onToggle={setIsOpen}
      trigger={
        <Trigger disabled={disabled} hasError={hasError}>
          {isOpen ? (
            <>
              <SearchInput
                style={inputStyle}
                ref={inputRef}
                onChange={e => setSearch(e.target.value)}
                placeholder={
                  Boolean(value) ? renderSelectedOption(value) : placeholder
                }
              />
              {loading && <Spinner size={20} variant="button" />}
            </>
          ) : (
            <>
              <PlaceHolderLabel disabled={disabled} style={{ overflow: 'hidden' }}>
                {Boolean(value) ? renderSelectedOption(value) : placeholder}
              </PlaceHolderLabel>
              {!deletable && <IcArrowDown size={24} />}
              {deletable && value && (
                <IcCancel
                  size={24}
                  onClick={removeFilter}
                  style={{ marginLeft: 5, minWidth: 24 }}
                />
              )}
            </>
          )}
        </Trigger>
      }
    >
      <PopoverContainer style={{ maxHeight: '100px' }}>
        <PopoverMenu>
          <PopoverMenuInner
            ref={popoverRef}
            autoHeight
            autoHeightMax={250}
            onUpdate={handleScroll}
          >
            {Boolean(countriesOptions?.nodes?.length > 0) &&
              (!kind || kind === 'country') && (
                <PopoverSearchItem
                  title="COUNTRIES"
                  kind="country"
                  data={countriesOptions}
                  value={value}
                  onChange={handleChange}
                />
              )}

            {Boolean(regionsOptions?.nodes?.length > 0) &&
              (!kind || kind === 'region') && (
                <PopoverSearchItem
                  title="REGIONS"
                  kind="region"
                  data={regionsOptions}
                  value={value}
                  onChange={handleChange}
                />
              )}

            {Boolean(citiesOptions?.nodes?.length > 0) &&
              (!kind || kind === 'city') && (
                <PopoverSearchItem
                  title="CITIES"
                  kind="city"
                  data={citiesOptions}
                  value={value}
                  onChange={handleChange}
                />
              )}

            {Boolean(hotelsOptions?.nodes?.length > 0) &&
              (!kind || kind === 'hotel') && (
                <PopoverSearchItem
                  title="HOTELS"
                  kind="hotel"
                  data={hotelsOptions}
                  value={value}
                  onChange={handleChange}
                />
              )}

            {Boolean(experiencesOptions?.length > 0) &&
              (!kind || kind === 'programs') && (
                <PopoverSearchItem
                  title="EXPERIENCES"
                  kind="programs"
                  data={experiencesOptions}
                  value={value}
                  onChange={handleChange}
                />
              )}
          </PopoverMenuInner>

          {(Boolean(popoverFooter) ||
            (Boolean(emptyState) && !loading && options.length === 0)) && (
            <PopoverFooter>{popoverFooter}</PopoverFooter>
          )}
        </PopoverMenu>
      </PopoverContainer>
    </Dropdown>
  );
};

LocationSearchableSelect.defaultProps = {
  placeholder: '',
  options: [],
  excludedIds: [],
  renderOption: ({ id }) => id,
  renderSelectedOption: ({ id }) => id,
};

export default LocationSearchableSelect;
