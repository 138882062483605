import { cva } from 'class-variance-authority';
import AllotmentsEditorEditModal from './AllotmentsEditorEditModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const button = cva([
  'py-1.5 px-5 rounded-md font-medium border-none transition'
], {
  variants: {
    disabled: {
      false: 'cursor-pointer bg-blue-500 hover:bg-blue-600 text-white',
      true: 'cursor-not-allowed bg-blue-500/40 text-white/50'
    }
  }
});

export default function AllotmentsEditorEditButton({ selectedDays, onApply }) {
  const { t } = useTranslation('allotments');

  const [showEditModal, setShowEditModal] = useState(false);

  const uniqueRoomsCount = selectedDays?.reduce((acc, curr) => {
    if (!acc.includes(curr.roomId)) {
      acc.push(curr.roomId);
    }

    return acc;
  }, [])?.length;

  return (
    <>
      <div className="py-3 w-full flex justify-center">
        <button
          onClick={() => setShowEditModal(true)}
          className={button({ disabled: selectedDays?.length === 0 })}
          type="button"
          disabled={selectedDays?.length === 0}
        >
          <i className="far fa-pen mr-1.5"/>
          {t('editor.editButton', { count: selectedDays?.length, rooms: uniqueRoomsCount })}
        </button>
      </div>

      <AllotmentsEditorEditModal
        selectedDays={selectedDays}
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        onApply={onApply}
      />
    </>
  );
}
